<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-12">
            <div class="card border-0 shadow-sm mb-4">
              <div class="card-body">
                <h5>
                  <span class="font-weight-bold">{{ organization.title }}</span>
                  <span class="float-right status" :class="activeClass">
                    <MailError v-if="!status" />
                    <Checkmark v-else />
                    <span>Status</span>
                  </span>
                </h5>
                <p class="text-muted mb-0">
                  Created {{ organization.created_at | formatDateHours }}
                </p>
              </div>
            </div>
            <div class="card border-0 shadow-sm mb-4">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-6 pr-0 pl-2">
                    <label for="name" class="control-label text-capitalize"
                      >Name</label
                    >
                    <input
                      id="name"
                      v-model="organization.name"
                      type="text"
                      name="name"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6 pr-0 pl-2">
                    <label for="email" class="control-label text-capitalize"
                      >Email</label
                    >
                    <input
                      id="email"
                      v-model="organization.admin_email"
                      type="text"
                      name="email"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6 pr-0 pl-2">
                    <label
                      for="automations"
                      class="control-label text-capitalize"
                      >Automations created</label
                    >
                    <input
                      id="automations"
                      v-model="organization.automationCount"
                      type="text"
                      name="automations"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6 pr-0 pl-2">
                    <label
                      for="automationsActivated"
                      class="control-label text-capitalize"
                      >Automations in the last 30 days</label
                    >
                    <input
                      id="automationsActivated"
                      v-model="organization.automationsActivated"
                      type="text"
                      name="automationsActivated"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6 pr-0 pl-2">
                    <label
                      for="orderCount"
                      class="control-label text-capitalize"
                      >Orders received</label
                    >
                    <input
                      id="orderCount"
                      v-model="organization.orderCount"
                      type="text"
                      name="orderCount"
                      class="form-control"
                      disabled
                    />
                  </div>
                  <div class="form-group col-md-6 pr-0 pl-2">
                    <label
                      for="customerCount"
                      class="control-label text-capitalize"
                      >Customers created</label
                    >
                    <input
                      id="customerCount"
                      v-model="organization.customerCount"
                      type="text"
                      name="customerCount"
                      class="form-control"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 shadow-sm mb-4">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-12 pr-0 pl-2">
                    <h4>
                      <span class="font-weight-bold">Goals and Roles</span>
                    </h4>
                  </div>
                  <div class="form-group col-md-12 pr-0 pl-2">
                    <h5>
                      <span class="font-weight-bold">What are your goals?</span>
                    </h5>
                    <p>
                      {{ organization.signup_goal ? organization.signup_goal : "not set" }}
                    </p>
                  </div>
                  <div class="form-group col-md-12 pr-0 pl-2">
                    <h5>
                      <span class="font-weight-bold">What are your roles?</span>
                    </h5>
                    <p>
                      {{ organization.signup_role ? organization.signup_role : "not set" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card border-0 shadow-sm mb-4">
          <div class="card-header border-bottom">
            <p class="mb-0">
              Onboarding steps completed
            </p>
          </div>
          <div class="card-body">
            <div v-for="step in onboardingsteps" :key="step.key">
              <div v-if="CheckOnboardingStatus(step.key) || step.key === 'crm'">
                <done-circle /> {{ step.name }}
              </div>
              <div v-else><error-circle /> {{ step.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import Checkmark from "@/components/Svg/Checkmark.vue";
import MailError from "@/components/Svg/MailError.vue";
import DoneCircle from "@/components/Svg/DoneCircle.vue";
import ErrorCircle from "@/components/Svg/ErrorCircle.vue";
import Onboarding from "@/helpers/onboarding";

export default {
  name: "CrmSingleView",
  components: { Default, Checkmark, MailError, DoneCircle, ErrorCircle },
  beforeRouteEnter(to, from, next) {
    const _id = to.params.id;
    jsonAPI.get("administration/crm/" + _id).then((response) => {
      next((vm) => {
        vm.organization = response.data;
      });
    });
  },
  data() {
    return {
      layout: "Default",
      organization: {
        id: null,
      },
      onboardingsteps: Onboarding,
    };
  },
  computed: {
    onboarding() {
      if (this.organization.settings && this.organization.settings.onboarding) {
        return this.organization.settings.onboarding;
      }
      return [];
    },
    status() {
      if (this.organization.settings && this.organization.settings.status) {
        return this.organization.settings.status;
      }
      return false;
    },
    statusColor() {
      if (this.organization.settings && this.organization.settings.status) {
        return this.organization.settings.status ? "succes" : "danger";
      }
      return "danger";
    },
    activeClass() {
      return !this.isActive ? "inactive" : "";
    },
  },
  methods: {
    CheckOnboardingStatus(step_key) {
      if (this.organization.settings) {
        let settings = this.organization.settings;
        if (typeof settings === "string") {
          settings = JSON.parse(settings);
        }
        if (settings !== null && "onboarding" in settings) {
          return settings.onboarding.some(function(find_key) {
            if (find_key.key === step_key) {
              return find_key.status;
            }
          });
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  svg {
    * {
      fill: #3fdc75;
    }
  }
  &.inactive svg * {
    fill: #f05a6e;
  }
}
</style>
