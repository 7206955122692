<template>
  <div>
    <div class="modal-body">
      <div class="icon">
        <delete-circle />
      </div>
      <div class="description">
        <h2>Are you sure?</h2>
        <p>
          Deleting an organization will also delete all there related information.
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary" @click="close">
        Cancel
      </button>
      <ButtonLoading
        :loading="loading"
        :class="'btn btn-outline-danger'"
        :title="'Delete'"
        v-on:click.native="deleteOrganization"
      />
    </div>
  </div>
</template>

<script>
import jsonAPI from "@/api";
import DeleteCircle from "@/components/Svg/DeleteCircle.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  components: {
    DeleteCircle,
    ButtonLoading,
  },
  props: {
    organizationId: {
      type: String,
      default: "",
    },
    organizations: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    close() {
      this.$parent.$emit("close");
    },
    deleteOrganization() {
      this.loading = true;

      let uri = "/administration/crm/"+ this.organizationId+"/delete/";
      jsonAPI
        .delete(uri)
        .then(() => {
          const filtered_organizations = this.organizations.filter((obj) => {
            return obj.id !== this.organizationId;
          });
          this.$emit("updateOrganizations", filtered_organizations);
          this.loading = false;
          this.$parent.$emit("close");
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped>
.modal-body {
  text-align: center;
}
.icon {
  margin-top: 20px;
  margin-bottom: 20px;
}
.modal-footer {
  border-top: none;
  justify-content: center;
  margin-bottom: 20px;
}
.modal-footer .btn {
  text-align: center;
}
</style>
