<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <delete-prompt
        :active="deleteAccount"
        @close="deleteAccount = false"
      >
        <div slot="content">
          <delete-organization
            :organizationId="organization_id"
            :organizations="organizations.data"
            @updateOrganizations="updateOrganizations"
          />
        </div>
      </delete-prompt>

      <div class="col-12">
        <div class="card border-0 shadow-sm mb-6">
          <div class="card-header d-flex flex-md-row justify-content-between">
            <div class="px-0 py-1 m-1">
              <h4>CRM</h4>
            </div>
            <div class="px-0 py-1 m-0">
              <div class="input-group">
                <input class="form-control" name="search" v-model="search" type="text" placeholder="Search..." aria-label="Search" aria-describedby="basic-addon2">
                <button type="submit" class="input-group-text" @click="getResults">Search</button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <data-table
              :headers="headers()"
              sort-url="/administration/crm"
              :data="organizations.data"
            >
              <template #title="{ item }">
                {{ item.title }}
              </template>
              <template #email="{ item }">
                <!-- get event name from given id -->
                {{ item.email }}
              </template>
              <template #domain="{ item }">
                {{ item.domain }}
              </template>
              <template #steps="{ item }">
                {{ item.steps }}
              </template>
              <template #plan="{ item }">
                {{ item.plan }}
              </template>
              <template #automations="{ item }">
                {{ item.automations }}
              </template>
              <template #last_login="{ item }">
                {{ item.last_login | formatDate }}
              </template>
              <template #date="{ item }">
                {{ item.created_at | formatDateHours }}
              </template>
              <template #actions="{ item }">
                <router-link
                  :to="{ name: 'admin.crm.single', params: { id: item.id } }"
                  class="btn btn-sm btn-outline-secondary"
                >
                  View customer
                </router-link>
                <button
                  type="submit"
                  class="btn btn-sm btn-outline-danger m-1"
                  @click="deleteOrganization(item.id)"
                >
                  Delete
                </button>
              </template>
            </data-table>
            <pagination
              :data="organizations"
              :show-disabled="true"
              @pagination-change-page="getResults"
            >
              <span slot="prev-nav" class="text-capitalize">
                <i class="fas fa-chevron-left mr-2" />
                Prev
              </span>
              <span slot="next-nav" class="text-capitalize">
                Next
                <i class="fas fa-chevron-right ml-2" />
              </span>
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>
<script>
import DataTable from "@/components/DataTable/DataTable.vue";
import Default from "@/layouts/default.vue";
import { loadData } from "@/helpers/apiConnection";
import { mapGetters } from "vuex";
import DeletePrompt from "@/components/UI/DeletePrompt.vue";
import DeleteOrganization from "@/components/Admin/DeleteOrganization.vue";

export default {
  name: "AdminIndex",
  components: {
    DataTable,
    Default,
    DeletePrompt,
    DeleteOrganization,
  },
  async beforeRouteEnter(to, from, next) {
    const res = await loadData(
      "/administration/crm",
      "page=1",
      to.query.sort ?? "",
      to.query.dir ?? "desc"
    ).catch(() => {
      window.Bus.$emit("flash-message", {
        text: "Access not allowed",
        type: "error",
      });
      next({ path: "/overview" });
    });
    if (res) {
      next((vm) => {
        vm.organizations = res;
      });
    }
  },
  async beforeRouteUpdate(to) {
    const sort = to.query.sort ?? "";
    const dir = to.query.dir ?? "desc";
    this.getResults(this.page, sort, dir);
  },
  data: function() {
    return {
      layout: "Default",
      organizations: {
        data: [],
      },
      page: 1,
      search: "",
      deleteAccount: false,
      organization_id: "",
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    headers() {
      return [
        { title: "Org Title", key: "title", sortable: true, sortkey: "title" },
        { title: "Admin name", key: "name", sortable: true, sortkey: "title" },
        {
          title: "Email",
          key: "admin_email",
          sortable: true,
          sortkey: "email",
        },
        { title: "Domain", key: "domain", sortable: true, sortkey: "domain" },
        {
          title: "Completed onboarding",
          key: "steps",
          sortable: true,
          sortkey: "steps",
        },
        {
          title: "Payment plan",
          key: "plan",
          sortable: false,
          sortkey: "plan",
        },
        {
          title: "Activation (30 days)",
          key: "automations",
          sortable: false,
          sortkey: "automations",
        },
        {
          title: "Last Login",
          key: "last_login",
          sortable: true,
          sortkey: "last_login",
        },
        {
          title: "Created on",
          key: "date",
          sortable: true,
          sortkey: "created_at",
        },
        { title: "Actions", key: "actions" },
      ];
    },
    // Our method to GET results from a Laravel endpoint
    async getResults(page = "1", sort = "title", dir = "asc", query = "") {
      this.page = page;
      if(this.search) {
       query = "search="+this.search;
      }
      const res = await loadData("/administration/crm", page, sort, dir, query).catch(
        (error) => {
          window.Bus.$emit("flash-message", {
            text: error.message,
            type: "error",
          });
        }
      );
      if (res) {
        this.organizations = res;
      }
    },
    deleteOrganization(organizationID) {
      this.organization_id = organizationID;
      this.deleteAccount = true;
    },
    updateOrganizations(event)
    {
      this.organizations.data = event;
    }
  },
};
</script>

<style scoped>
.input-group button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
