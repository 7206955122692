var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('div',{staticClass:"row fadeInUp",attrs:{"role":"main"}},[_c('delete-prompt',{attrs:{"active":_vm.deleteAccount},on:{"close":function($event){_vm.deleteAccount = false}}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('delete-organization',{attrs:{"organizationId":_vm.organization_id,"organizations":_vm.organizations.data},on:{"updateOrganizations":_vm.updateOrganizations}})],1)]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card border-0 shadow-sm mb-6"},[_c('div',{staticClass:"card-header d-flex flex-md-row justify-content-between"},[_c('div',{staticClass:"px-0 py-1 m-1"},[_c('h4',[_vm._v("CRM")])]),_c('div',{staticClass:"px-0 py-1 m-0"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"name":"search","type":"text","placeholder":"Search...","aria-label":"Search","aria-describedby":"basic-addon2"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('button',{staticClass:"input-group-text",attrs:{"type":"submit"},on:{"click":_vm.getResults}},[_vm._v("Search")])])])]),_c('div',{staticClass:"card-body"},[_c('data-table',{attrs:{"headers":_vm.headers(),"sort-url":"/administration/crm","data":_vm.organizations.data},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"domain",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.domain)+" ")]}},{key:"steps",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.steps)+" ")]}},{key:"plan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan)+" ")]}},{key:"automations",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.automations)+" ")]}},{key:"last_login",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.last_login))+" ")]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateHours")(item.created_at))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"btn btn-sm btn-outline-secondary",attrs:{"to":{ name: 'admin.crm.single', params: { id: item.id } }}},[_vm._v(" View customer ")]),_c('button',{staticClass:"btn btn-sm btn-outline-danger m-1",attrs:{"type":"submit"},on:{"click":function($event){return _vm.deleteOrganization(item.id)}}},[_vm._v(" Delete ")])]}}])}),_c('pagination',{attrs:{"data":_vm.organizations,"show-disabled":true},on:{"pagination-change-page":_vm.getResults}},[_c('span',{staticClass:"text-capitalize",attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_c('i',{staticClass:"fas fa-chevron-left mr-2"}),_vm._v(" Prev ")]),_c('span',{staticClass:"text-capitalize",attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v(" Next "),_c('i',{staticClass:"fas fa-chevron-right ml-2"})])])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }